import React, { FC, useEffect } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
//import useReactRouter from 'use-react-router'
import { LoginPage } from './pages/login'
import { AIChatPage } from './pages/chat'
import { AppProvider } from './stores/store'
import { useChangeLocalhostUrl } from './hooks/useChangeLocalhostUrl'
import { useRedirectToLogin } from './hooks/useRedirectToLogin'
import { useShowMaintenanceModal } from './hooks/useShowMaintenanceModal'
import { ThemeProvider } from '@mui/material/styles'
import IndexPage from './pages/index'
import Amplitude from './utils/analytics/amplitude'
import { MedChangePage } from './pages/medChange'
import { SupportChatPage } from './pages/supportChat'
import { Dashboard } from './pages/dashboard'
import { useCheckAuth } from './hooks/useCheckAuth'
import { useGetAppType } from './hooks/useGetAppType'
import { useGetTheme } from './hooks/useGetTheme'

Amplitude.init()

const handleDocumentClick = (event: MouseEvent) => {
  const element = event.target as HTMLElement
  if (element.tagName == 'BUTTON' || element.closest('button') != null) {
    Amplitude.logEvent({
      event: 'BUTTON_CLICK',
      properties: {
        tagName: element.tagName,
        id: element.id,
        timestamp: new Date().toISOString(),
        pageUrl: window.location.href,
      },
    })
  }
}

const Pages = () => {
  useRedirectToLogin()
  useCheckAuth()
  const theme = useGetTheme()
  const MaintenanceModal = useShowMaintenanceModal()
  return (
    <>
      <ThemeProvider theme={theme}>
        {MaintenanceModal}
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/med_change" component={MedChangePage} />
        <Route exact path="/beta/support_chat" component={SupportChatPage} />
        <Route exact path="/beta/dashboard" component={Dashboard} />
      </ThemeProvider>
    </>
  )
}

const App: FC = () => {
  useChangeLocalhostUrl()
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
  }, [])
  return (
    <AppProvider>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>
    </AppProvider>
  )
}

export default App
