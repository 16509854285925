import React, { CSSProperties, useEffect, useState } from 'react'
import { PrescImage } from '../types/models/PrescImage'
import { PrescriptionImageBox } from '../components/molecules/PrescriptionImageBox'
import { Box, useTheme } from '@mui/material'
import Amplitude from '../utils/analytics/amplitude'
import { ImageArea } from '../types/models/Presc'
import { localStorageWrapper } from '../helpers/localStorage'

export const usePrescriptionImageBox = (
  prescriptionImages: PrescImage[],
  imageArea: ImageArea[]
) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [prescImageShown, setPrescImageShown] = useState(false)
  const [isZoomed, setIsZoomed] = useState(true)
  const [style, setStyle] = useState({} as CSSProperties)
  const [containerAspectRatio, setContainerAspectRatio] = useState(
    'auto' as string
  )
  const theme = useTheme()

  useEffect(() => {
    currentIndex &&
      !currentUrl &&
      Amplitude.logEvent({
        event: 'NoImage',
      })
  }, [prescriptionImages])

  const currentUrl = prescriptionImages[currentIndex]?.image_url
  const showNextImage = () => {
    const isLast = currentIndex === prescriptionImages.length - 1
    isLast ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1)
  }

  const setImageStyle = (target: HTMLImageElement, isZoomed: boolean) => {
    const currentImageArea = imageArea[currentIndex]
    const isImageRotatable = localStorageWrapper.getItem('isImageRotatable')
    if (!isImageRotatable || !currentImageArea) {
      setStyle({ width: '100%' })
      return
    }

    // original image size
    const aspectRatio = target.naturalHeight / target.naturalWidth
    const prescriptionAreaAspectRatio =
      getLength(
        currentImageArea[1],
        currentImageArea[2],
        target.width,
        target.height
      ) /
      getLength(
        currentImageArea[0],
        currentImageArea[1],
        target.width,
        target.height
      )

    if (isZoomed) {
      setStyle(getZoomedStyle(currentImageArea, aspectRatio))
      setContainerAspectRatio(`1 / ${prescriptionAreaAspectRatio}`)
    } else {
      setStyle({ width: '100%' })
      setContainerAspectRatio(`1 / ${aspectRatio}`)
    }
  }

  const PrescImageBox = currentUrl && (
    <PrescriptionImageBox
      imageIndex={currentIndex}
      imagesLength={prescriptionImages.length}
      imageUrl={currentUrl}
      onPageIndicatorClick={showNextImage}
      onLoad={(event) => {
        const target = event.target as HTMLImageElement
        setImageStyle(target, true)
        setIsZoomed(true)
      }}
      onImageClick={(event) => {
        const target = event.target as HTMLImageElement
        setImageStyle(target, !isZoomed)
        setIsZoomed(!isZoomed)
      }}
      imageStyle={style}
      styleForSmallScreen={
        prescImageShown ? { width: '100%' } : { display: 'none' }
      }
      containerAspectRatio={containerAspectRatio}
    />
  )

  const PrescImageToggleButton = (
    <Box
      sx={{
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }}
      style={
        prescImageShown ? { right: 0, position: 'fixed', height: '100%' } : {}
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={16}
      bgcolor={'secondary.dark'}
      color="#fff"
      onClick={() =>
        prescImageShown ? setPrescImageShown(false) : setPrescImageShown(true)
      }
    >
      {prescImageShown ? '処方箋を閉じる' : '処方箋を開く'}
    </Box>
  )

  return { prescImageShown, PrescImageBox, PrescImageToggleButton }
}

const getLength = (
  point1: { x座標: number; y座標: number },
  point2: { x座標: number; y座標: number },
  width: number,
  height: number
) =>
  Math.sqrt(
    Math.pow((point1.x座標 - point2.x座標) * width, 2) +
      Math.pow((point1.y座標 - point2.y座標) * height, 2)
  )

const calculateRotationInDeg = (target: ImageArea): number => {
  return (
    -Math.atan2(
      target[1].y座標 - target[0].y座標,
      target[1].x座標 - target[0].x座標
    ) *
    (180 / Math.PI)
  )
}

const calculateZoomRatio = (target: ImageArea, aspectRatio: number): number => {
  const x = target[1].x座標 - target[0].x座標
  const y = (target[1].y座標 - target[0].y座標) * aspectRatio
  return 1 / Math.sqrt(x * x + y * y)
}

const intoPercent = (value: number): string => {
  return `${value * 100}%`
}

const getZoomedStyle = (
  target: ImageArea,
  aspectRatio: number
): CSSProperties => {
  const rotation = calculateRotationInDeg(target)
  const zoomRatio = calculateZoomRatio(target, aspectRatio)
  const origin = target[0]

  return {
    width: '100%',
    transform:
      `translate(${intoPercent(-origin.x座標)}, ` +
      `${intoPercent(-origin.y座標)}) ` +
      `rotate(${rotation}deg) ` +
      `scale(${zoomRatio})`,
    transformOrigin:
      `${intoPercent(origin.x座標)} ` + `${intoPercent(origin.y座標)}`,
  }
}
