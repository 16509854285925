import { Box, Typography } from '@mui/material'
import { FC } from 'react'

type Props = {
  text: string
}

export const PatientMessageBalloon: FC<Props> = ({ text }) => {
  const formattedText = text.replace(/\n/g, '<br />')

  return (
    <Box
      sx={{
        position: 'relative',
        padding: '12px',
        backgroundColor: 'grey.100',
        width: 'auto',
        textAlign: 'left',
        border: '1px solid',
        borderColor: 'grey.100',
        borderRadius: '10px',
        '&:after': {
          content: "''",
          position: 'absolute',
          width: '0',
          height: '0',
          borderBottom: '15px solid',
          borderColor: 'grey.100',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          bottom: '0',
          right: '-15px',
        },
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '0',
          height: '0',
          borderBottom: '15px solid',
          borderColor: 'grey.100',
          borderLeft: '16px solid transparent',
          borderRight: '16px solid transparent',
          bottom: '-1px',
          right: '-17px',
        },
      }}
    >
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    </Box>
  )
}
