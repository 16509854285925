import { CircularProgress, Stack, Typography } from '@mui/material'
import { FC } from 'react'

export const SendingOverlay: FC = () => (
  <Stack
    direction="column"
    spacing={8}
    position="fixed"
    bgcolor="rgba(0, 0, 0, 0.5)"
    color="#fff"
    style={{
      inset: 0,
      margin: 'auto',
      textAlign: 'center',
      alignItems: 'center',
      zIndex: 1300,
      justifyContent: 'center',
    }}
  >
    <Typography
      style={{
        width: '100%',
        height: '32px',
      }}
    >
      メッセージをAIに送信中です <br></br>
      しばらくお待ちください
    </Typography>
    <CircularProgress
      style={{
        width: '80px',
        height: '80px',
        color: '#fff',
      }}
    />
  </Stack>
)
