import React, { CSSProperties, useEffect } from 'react'
import { Stack, Box, useTheme, CircularProgress } from '@mui/material'
import { useMemo } from 'react'
import { memo } from 'react'
import { NamedExoticComponent } from 'react'
import { useState } from 'react'

type Props = {
  imagesLength: number
  imageIndex: number
  imageUrl: string
  onPageIndicatorClick: () => void
  onImageClick: React.MouseEventHandler<HTMLImageElement>
  onLoad: React.ReactEventHandler<HTMLImageElement>
  imageStyle: CSSProperties
  styleForSmallScreen: CSSProperties
  containerAspectRatio: string
}

export const PrescriptionImageBox: NamedExoticComponent<Props> = memo(
  ({
    imagesLength,
    imageIndex,
    imageUrl,
    onPageIndicatorClick,
    onImageClick,
    onLoad,
    imageStyle,
    styleForSmallScreen,
    containerAspectRatio,
  }) => {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      const image = new Image()
      setLoading(true)
      image.onload = () => {
        setLoading(false)
      }
      image.src = imageUrl
    }, [imageUrl])

    const PrescImage = useMemo(
      () => (
        <div
          style={{
            width: '100%',
            aspectRatio: containerAspectRatio,
            overflow: 'hidden',
          }}
        >
          <img
            src={imageUrl}
            style={imageStyle}
            onLoad={onLoad}
            onClick={onImageClick}
          />
        </div>
      ),
      [imageUrl, imageStyle]
    )
    return (
      <Box
        sx={{
          [theme.breakpoints.down('md')]: styleForSmallScreen,
        }}
        width="50%"
      >
        <Stack
          sx={{
            [theme.breakpoints.up('md')]: {
              position: 'relative',
            },
            height: '100%', // Make sure the Stack takes full height
            justifyContent: 'center', // Center children horizontally
            alignItems: 'center', // Center children vertically
          }}
          direction="row"
          bgcolor="#FFF"
          p={1}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: theme.palette.secondary.dark,
                opacity: 0.3,
              }}
              size={80}
            />
          ) : (
            PrescImage
          )}
          <Box
            sx={{
              [theme.breakpoints.down('md')]: {
                right: 24,
              },
            }}
            position="absolute"
            top={8}
            right={24}
            bgcolor="#000"
            color="#fff"
            p={1}
            borderRadius="8px"
            style={{ opacity: 0.6 }}
            onClick={onPageIndicatorClick}
          >
            {`${imageIndex + 1}/${imagesLength}ページ中 ›`}
          </Box>
        </Stack>
      </Box>
    )
  }
)

PrescriptionImageBox.displayName = 'PrescriptionImageBox'
