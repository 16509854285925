import React, { FC } from 'react'
import { Box, TextField, FormControl } from '@mui/material'

type Props = {
  label: string
  value: string
  size: 'small' | 'medium'
  onChange: (v: string) => void
}

export const ChatBox: FC<Props> = ({ label, value, onChange, size }) => (
  <FormControl fullWidth color="primary">
    <TextField
      variant="outlined"
      size={size}
      label={label}
      value={value}
      multiline
      onChange={(e) => onChange(e.target.value)}
    />
  </FormControl>
)
